import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "rounded-lg border divide-y divide-gray-100 bg-white"
}
const _hoisted_2 = { class: "heading" }
const _hoisted_3 = {
  key: 0,
  class: "flex-none w-[160px]"
}
const _hoisted_4 = {
  key: 1,
  class: "flex-none w-[160px]"
}
const _hoisted_5 = {
  key: 2,
  class: "flex-none w-[160px]"
}
const _hoisted_6 = {
  key: 3,
  class: "flex-none w-[100px]"
}
const _hoisted_7 = {
  key: 0,
  class: "no-data bg-white"
}

import ListItem from "./list-item/list-item.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'list',
  props: {
	showHeader: {
		type: Boolean,
		default: true,
	},
	items: {
		type: Array as () => any[],
		default: () => [],
	},
	visibleItems: Array as () => string[],
	selectedUserId: String,
},
  emits: ["onDetailsClick", "onEditClick"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const hasField = (value: string) =>
	(props.visibleItems || []).indexOf(value) > -1;

const handleDetailsClick = (userId: string) => emit("onDetailsClick", userId);

const handleEditClick = (userId: string) => emit("onEditClick", userId);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-center flex-none w-16" }, [
          _createElementVNode("label", null, "Active")
        ], -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "min-w-0 flex-auto" }, [
          _createElementVNode("label", null, "User")
        ], -1)),
        (hasField('role'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
              _createElementVNode("label", null, "Role", -1)
            ])))
          : _createCommentVNode("", true),
        (hasField('city'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
              _createElementVNode("label", null, "City", -1)
            ])))
          : _createCommentVNode("", true),
        (hasField('declarantTypeLabel'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
              _createElementVNode("label", null, "Declarant Type", -1)
            ])))
          : _createCommentVNode("", true),
        (hasField('connections'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[3] || (_cache[3] = [
              _createElementVNode("label", null, "Connections", -1)
            ])))
          : _createCommentVNode("", true),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-none w-[70px]" }, null, -1)),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-none w-14" }, " ", -1))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items || [], (item, index) => {
        return (_openBlock(), _createBlock(ListItem, {
          key: index,
          item: item,
          selectedItemId: __props.selectedUserId,
          visibleItems: __props.visibleItems,
          onOnDetailsClick: handleDetailsClick,
          onOnEditClick: handleEditClick
        }, null, 8, ["item", "selectedItemId", "visibleItems"]))
      }), 128))
    ]),
    (!__props.items || __props.items.length === 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_7, _cache[8] || (_cache[8] = [
          _createElementVNode("li", null, "No users found", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}
}

})