import { IUniqueExport } from "@/interfaces/users/iUniqueExport";
import { IUserExportsResponse } from "../interfaces/IUserExportsResponse";

const mapExportResponsePeriod = (period: string) => ({
	year: period.split("-")[0],
	month: period.split("-")[1],
});

export const mapUserExportsResponse = (
	data: IUserExportsResponse
): IUniqueExport[] => {
	const exports = (data.aggregate || []).map((item) => {
		const itemExports = {
			month: Number(mapExportResponsePeriod(item.month).month),
			year: Number(mapExportResponsePeriod(item.month).year),
			total: item.companies.length,
			companyNames: item.companies,
		};

		return itemExports;
	});

	return exports;
};
