import IUserListViewModel from "@/components/administration/interfaces/iUserListViewModel";
import DECLARANT_TYPE from "@/constants/declarantType";
import IUser from "@/interfaces/users/iUser";

export const mapUsersData = (
	users: any[],
	config: {
		visibleFields: string[];
	}
): any[] => {
	const getMappedUsers: IUserListViewModel[] = (users || []).map(
		(user: IUser) => {
			const { id, role, active, profileData, email, connections } = user;
			const { firstName, middleName, lastName, declarantType, city } =
				profileData;

			const mappedUser: { [key: string]: any } = {
				id,
				active,
				role,
				fullName:
					firstName || lastName
						? `${firstName || ""} ${middleName || ""} ${lastName || ""}`
						: "N/A",
				email,
				city: city ?? "N/A",
				declarantTypeLabel:
					(DECLARANT_TYPE as { [k: string]: string })[declarantType] ?? "N/A",
				connections: connections ?? [],
			};

			const viewModel: { [key: string]: any } = {};
			config.visibleFields.forEach((f) => (viewModel[f] = mappedUser[f]));

			return viewModel as IUserListViewModel;
		}
	);

	return getMappedUsers;
};
