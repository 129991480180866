import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import HoModalDialog from "@/components/modal/ho-modal-dialog.vue";
import CreateUserForm from "./createUserForm/index.vue";
import IUser from "@/interfaces/users/iUser";


export default /*@__PURE__*/_defineComponent({
  __name: 'create-user-dialog',
  props: {
	userData: {
		type: Object as () => IUser,
	},
	editMode: Boolean,
	loading: Boolean
},
  emits: ['onSubmit', 'onCancel'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const handleCreateUserDialogClose = (data: any) => {
	data ? emit('onSubmit', data) : emit('onCancel');
};

const handleModalCancel = () => {
	emit('onCancel');
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoModalDialog, {
    headerText: __props.editMode ? 'Edit user data' : 'Create a user',
    "hide-footer": true,
    width: 480,
    onOnCancel: handleModalCancel
  }, {
    body: _withCtx(() => [
      _createVNode(CreateUserForm, {
        loading: __props.loading,
        "edit-mode": __props.editMode,
        "user-data": __props.userData,
        onOnClose: handleCreateUserDialogClose
      }, null, 8, ["loading", "edit-mode", "user-data"])
    ]),
    _: 1
  }, 8, ["headerText"]))
}
}

})