import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "header flex w-full items-center" }
const _hoisted_2 = { class: "w-8 h-8 text-center" }
const _hoisted_3 = { class: "filters flex w-full items-center justify-between" }
const _hoisted_4 = { class: "flex items-center pb-0 ho-search-bar" }
const _hoisted_5 = { class: "users flex w-full" }
const _hoisted_6 = {
  key: 0,
  class: "user-details flex-1"
}
const _hoisted_7 = { class: "flex rounded-t-lg border bg-gray-100 text-gray-500 align-middle justify-between px-3 py-3" }

import { Ref, onBeforeMount, ref, watch } from "vue";
import { LocationQuery, RouterView, useRoute } from "vue-router";
import router from "@/router";
import { createUser, getUsers, updateUser } from "@/services/usersApi";
import List from "@/components/administration/users/list/list.vue";
import { XIcon, HomeIcon } from "@heroicons/vue/outline";
import IUser from "@/interfaces/users/iUser";
import CreateUserDialog from "@/components/administration/users/create-user-dialog.vue";
import UsersSearchBar from "@/components/administration/users/usersSearchBar/index.vue";
import { mapUsersData } from "./utils";
import { hiddenFields, visibleFields } from "./config";

interface IUsersQueryFilters {
	searchField?: string;
	searchText?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Users',
  setup(__props) {

const selectOptionsList = [
	{
		label: "Name",
		value: "fullName",
	},
	{
		label: "Email",
		value: "email",
	},
];

const route = useRoute();

const getSelectedUserData = (userId?: string) => {
	if (!userId) {
		return undefined;
	}

	return usersData.value?.find((u) => u.id.toString() === userId);
};

const params = route.params;
const queryParams = route.query;
const id: string | undefined = params.id as string;

const usersData: Ref<IUser[]> = ref([]);
const usersViewModel: Ref<any[]> = ref([]);
const detailsOpen = ref(false);
const selectedUserId: Ref<string | undefined> = ref(id || undefined);
const selectedUserData: Ref<IUser | undefined> = ref(getSelectedUserData(id));
const editUserData: Ref<IUser | undefined> = ref(undefined);
const createUserOpen = ref(false);
const loading = ref({ user: false });
const editMode = ref(false);

const initQueryParams = {
	searchField: queryParams.seachField?.toString() ?? selectOptionsList[0].value.toString(),
	searchText: queryParams.searchText?.toString() ?? "",
};
const selectedFilters = ref(initQueryParams);
const lastQueryParams = ref(initQueryParams);

const setUsersList = (allUsers: IUser[], queryFilters?: IUsersQueryFilters, condenseList = false) => {
	if (detailsOpen.value === true) {
		return usersViewModel.value;
	}

	const config = {
		visibleFields: condenseList
			? [...visibleFields]
			: [...visibleFields, ...hiddenFields],
	};
	const mappedUsers = mapUsersData(allUsers, config);

	if (queryFilters?.searchField && queryFilters?.searchField.length > 0) {
		const foundUsers = mappedUsers.filter((user) => {
			if (
				queryFilters?.searchField &&
				user[queryFilters.searchField] &&
				user[queryFilters?.searchField]
					.toLowerCase()
					.indexOf(queryFilters?.searchText?.toLowerCase()) >= 0
			) {
				return user;
			}
		});

		usersViewModel.value = foundUsers;
	} else {
		usersViewModel.value = [...mappedUsers];
	}
};

const fetchUsers = async () => {
	usersData.value = await getUsers();
	const queryFilters = parseQueryFilters(queryParams);

	setUsersList(usersData.value, queryFilters);

	if (selectedUserId.value) {
		const selectedUser = getSelectedUserData(selectedUserId.value);
		selectedUserData.value = selectedUser;
	}
};

onBeforeMount(fetchUsers);

// TODO: General composable to handle query fiters
const parseQueryFilters = (
	query?: LocationQuery
): IUsersQueryFilters | undefined => {
	if (!query) {
		return undefined;
	}

	const filters: { [key: string]: string } = {};
	for (const key in query) {
		(filters as any)[key] = query[key];
	}

	return filters as unknown as IUsersQueryFilters;
};

watch(
	() => route.query,
	(newParams) => {
		if (route.name === 'Users') {
			const filters = parseQueryFilters(newParams);
			setUsersList(usersData.value, filters);

			if (detailsOpen.value === true) {
				detailsOpen.value = false;
			}
		}
	}
);

const handleDetailsOpen = (userId: string) => {
	const selectedUser = getSelectedUserData(userId);
	selectedUserData.value = selectedUser;
	selectedUserId.value = userId;

	setUsersList(usersData.value, lastQueryParams.value, true);
	setDetailsOpenView(true);

	router.push({ name: "User", params: { id: userId } });
};

const handleDetailsClose = () => {
	setDetailsOpenView(false);
	selectedUserId.value = undefined;
	selectedUserData.value = undefined;

	if (lastQueryParams.value.searchText?.length > 0) {
		router.push({
			path: "/admin/users",
			query: lastQueryParams.value,
		});
	} else {
		router.push("/admin/users");
	}
};

const setDetailsOpenView = (open: boolean) => {
	detailsOpen.value = open;
};

const handleOpenCreateUser = () => {
	editUserData.value = undefined;
	editMode.value = false;
	createUserOpen.value = true;
};

const handleOpenEditUser = (userId: string) => {
	editUserData.value = getSelectedUserData(userId);
	editMode.value = true;
	createUserOpen.value = true;
};

const handleCloseCreateUser = () => {
	editUserData.value = undefined;
	editMode.value = false;
	createUserOpen.value = false;
};

const handleCreateEditUser = async (data: any) => {
	editMode.value
		? editUserData.value?.id &&
		(await updateUser({ ...data, id: editUserData.value?.id }))
		: await createUser(data);

	handleCloseCreateUser();
	fetchUsers();
};

const goToHome = () => router.push("/vat-explorer");

const handleSearchUsers = (search: {
	searchField: string;
	searchText: string;
}) => {
	if (detailsOpen.value === true) {
		handleDetailsClose();
	}

	if (search.searchText?.length > 0) {
		const newQueryParams = { ...search };

		lastQueryParams.value = newQueryParams;

		router.push({
			path: "/admin/users",
			query: newQueryParams,
		});
	} else router.push("/admin/users");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "flex-auto" }, "Users", -1)),
      _createElementVNode("button", {
        class: "btn flex",
        onClick: goToHome
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_unref(HomeIcon), { class: "w-7 h-7" })
        ]),
        _cache[0] || (_cache[0] = _createTextVNode(" Home "))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(UsersSearchBar, {
          selected: selectedFilters.value,
          "search-options": selectOptionsList,
          onOnSearch: handleSearchUsers
        }, null, 8, ["selected"])
      ]),
      _createElementVNode("button", {
        class: "btn flex-none",
        onClick: handleOpenCreateUser
      }, " Create user ")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["user-list flex-1", selectedUserId.value && 'condensed'])
      }, [
        (_openBlock(), _createBlock(List, {
          items: usersViewModel.value,
          "visible-items": selectedUserId.value
				? [..._unref(visibleFields)]
				: [..._unref(visibleFields), ..._unref(hiddenFields)]
				,
          selectedUserId: selectedUserId.value,
          key: selectedUserId.value,
          onOnDetailsClick: handleDetailsOpen,
          onOnEditClick: handleOpenEditUser
        }, null, 8, ["items", "visible-items", "selectedUserId"]))
      ], 2),
      (selectedUserData.value?.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[2] || (_cache[2] = _createElementVNode("label", { class: "flex-1" }, "User Details", -1)),
              _createVNode(_unref(XIcon), {
                class: "h-8 w-8",
                onClick: handleDetailsClose
              })
            ]),
            _createVNode(_unref(RouterView), {
              user: selectedUserData.value,
              onOnReloadTable: fetchUsers
            }, null, 8, ["user"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (createUserOpen.value)
      ? (_openBlock(), _createBlock(CreateUserDialog, {
          key: 0,
          loading: loading.value.user,
          "user-data": editUserData.value,
          editMode: editMode.value,
          onOnSubmit: handleCreateEditUser,
          onOnCancel: handleCloseCreateUser
        }, null, 8, ["loading", "user-data", "editMode"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})