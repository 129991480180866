
import { GetTranslationFunc } from '@/types/translationTypes';
import { IValidator } from '@/utils/validator';

export type ValidationField = 'userName' | 'password' | 'email';

export const getValidators = (getTranslation: GetTranslationFunc): { [key:string]: IValidator[] }  => {
	const validators: {
		[key:string]: IValidator[]
	} = {
		password: [{
			type: 'len',
			options: {
				min: 8
			},
			message: getTranslation('user-data-dialog.validators.password-length', { min: 8 })
		},{
			type: 'pattern',
			options: {
				pattern: /[ !"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]{1,}/
			},
			message: getTranslation('user-data-dialog.validators.password-symbols', { min: 1 })
		},{
			type: 'pattern',
			options: {
				pattern: /\d{1,}/
			},
			message: getTranslation('user-data-dialog.validators.password-digits', { min: 1 })
		}],
		userName: [{
			type: 'notEmpty',
			message: getTranslation('common.validators.not-empty')
		}],
		email: [{
			type: 'notEmpty',
			message: getTranslation('common.validators.not-empty')
		}]
	}

	return validators;
}