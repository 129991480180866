import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper-admin" }
const _hoisted_2 = { class: "ho-app ho-app-admin" }

import IUser from '@/interfaces/users/iUser';
import { RouterView } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Admin',
  props: {
	user: {
		type: Object as () => IUser,
	},
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(RouterView), { user: __props.user }, null, 8, ["user"])
    ])
  ]))
}
}

})