import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ho-search-bar flex" }
const _hoisted_2 = { class: "ho-autoselect" }
const _hoisted_3 = { class: "ho-search-input" }
const _hoisted_4 = { class: "w-8 h-8 text-center" }

import { ref } from "vue";
import Autocomplete from "@/components/form/autocomplete.vue";
import { SearchIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
	selected: Object as () => { searchField: string; searchText: string },
	searchOptions: {
		type: Array as () => { label: string; value: string }[],
		default: () => [],
	},
},
  emits: ["update:modelValue", "onSearch", "onChange"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const searchFilter = ref(
	props.selected?.searchField ?? props.searchOptions[0].value
);
const searchText = ref(props.selected?.searchText ?? "");

const handleChangeInput = (value: any) => {
	if (value.target.value === "") {
		handleSearch();
	}
};

const handleChangeSearchCriteria = (value: string) => {
	searchFilter.value = value;
};

const handleSearch = () => {
	emit("onSearch", {
		searchField: searchFilter.value,
		searchText: searchText.value,
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Autocomplete, {
        options: __props.searchOptions,
        "default-value": searchFilter.value,
        "can-clear": false,
        onOnChange: handleChangeSearchCriteria
      }, null, 8, ["options", "default-value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        autocomplete: "none",
        "aria-autocomplete": "none",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
        placeholder: `Search by name or email...`,
        onInput: handleChangeInput,
        onKeyup: _withKeys(handleSearch, ["enter"])
      }, null, 544), [
        [_vModelText, searchText.value]
      ]),
      _createElementVNode("button", {
        class: "flex ho-search-icon",
        onClick: handleSearch
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_unref(SearchIcon), { class: "w-7 h-7" })
        ])
      ])
    ])
  ]))
}
}

})