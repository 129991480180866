<template>
	<li class="flex items-center justify-between gap-x-4 px-3 py-0" :class="selectedItemId === item?.id && 'bg-gray-100'">
		<div class="flex-none py-3 w-16 text-center">
			<CheckCircleIcon v-if="item?.active" class="h-10 w-10 text-green-600" />
			<XCircleIcon v-if="!item?.active" class="h-10 w-10 text-gray-500" />
		</div>
		<div class="min-w-0 flex-auto">
			<label class="text-2xl font-bold text-gray-900">{{
				item?.fullName
			}}</label><br />
			<label class="text-gray-900">{{ item?.email }}</label>
		</div>
		<div class="flex-none w-[160px]" v-if="hasField('city')">
			<label>{{ item?.role }}</label>
		</div>
		<div class="flex-none w-[160px]" v-if="hasField('city')">
			<label>{{ item?.city }}</label>
		</div>
		<div class="flex-none w-[160px]" v-if="hasField('declarantTypeLabel')">
			<label>{{ item?.declarantTypeLabel }}</label>
		</div>
		<div class="flex-none w-[100px]" v-if="hasField('connections')">
			<label>{{ item?.connections?.length }}</label>
		</div>

		<div class="flex-none w-[70px]" v-if="!selectedItemId || selectedItemId === item?.id">
			<button class="btn btn-xs flex items-end" @click="handleEditItemClick">
				<span class="w-7 h-7 text-center"><pencil-alt-icon class="w-6 h-7" /></span>
				Edit
			</button>
		</div>
		<div class="flex-none py-3 w-14" @click="handleDetailsClick">
			<p>
				<ChevronRightIcon class="h-8 w-8 text-gray-600" />
			</p>
		</div>
	</li>
</template>

<script setup lang="ts">
import {
	CheckCircleIcon,
	XCircleIcon,
	ChevronRightIcon,
	PencilAltIcon,
} from "@heroicons/vue/outline";
import IUserListViewModel from "@/components/administration/interfaces/iUserListViewModel";

const props = defineProps({
	item: Object as () => IUserListViewModel,
	selectedItemId: String,
	visibleItems: Array as () => string[],
});

const emit = defineEmits(["onDetailsClick", "onEditClick"]);

const handleDetailsClick = () => emit("onDetailsClick", props.item?.id);

const handleEditItemClick = () => emit("onEditClick", props.item?.id);

const hasField = (value: string) => (props?.visibleItems || []).indexOf(value) > -1;

</script>

<style lang="postcss" scoped>
li svg {
	display: inline;
}
</style>
