import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import VatExplorer from "@/views/vatExplorer/index.vue";
import Admin from "@/views/administration/Admin.vue";
import Users from "@/views/administration/users/Users.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		redirect: { path: "/vat-explorer" },
		component: Home,
		children: [
			{
				path: "/vat-explorer",
				name: "VatExplorer",
				component: VatExplorer,
			},
		],
	},
	{
		path: "/login",
		name: "Login",
		// route level code-splitting
		// this generates a separate chunk (login.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "login" */ "../views/Login.vue"),
	},
	{
		path: "/admin",
		name: "Admin",
		component: Admin,
		redirect: { path: "/admin/users" },
		children: [
			{
				path: "users",
				name: "Users",
				component: Users,
				children: [
					{
						path: ":id",
						name: "User",
						component: () =>
							import(
								/* webpackChunkName: "users" */ "../views/administration/users/userDetails/UserDetails.vue"
							),
					},
				],
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
