<template>
	<ul role="list" class="rounded-lg border divide-y divide-gray-100 bg-white">
		<li class="heading">
			<div class="text-center flex-none w-16">
				<label>Active</label>
			</div>
			<div class="min-w-0 flex-auto">
				<label>User</label>
			</div>
			<div class="flex-none w-[160px]" v-if="hasField('role')">
				<label>Role</label>
			</div>
			<div class="flex-none w-[160px]" v-if="hasField('city')">
				<label>City</label>
			</div>
			<div class="flex-none w-[160px]" v-if="hasField('declarantTypeLabel')">
				<label>Declarant Type</label>
			</div>
			<div class="flex-none w-[100px]" v-if="hasField('connections')">
				<label>Connections</label>
			</div>
			<div class="flex-none w-[70px]"></div>
			<div class="flex-none w-14">&nbsp;</div>
		</li>
		<list-item v-for="(item, index) in items || []" :key="index" :item="item" :selectedItemId="selectedUserId"
			:visibleItems="visibleItems" @on-details-click="handleDetailsClick" @on-edit-click="handleEditClick" />
	</ul>
	<ul class="no-data bg-white" v-if="!items || items.length === 0">
		<li>No users found</li>
	</ul>
</template>

<script setup lang="ts">
import ListItem from "./list-item/list-item.vue";

const props = defineProps({
	showHeader: {
		type: Boolean,
		default: true,
	},
	items: {
		type: Array as () => any[],
		default: () => [],
	},
	visibleItems: Array as () => string[],
	selectedUserId: String,
});

const emit = defineEmits(["onDetailsClick", "onEditClick"]);

const hasField = (value: string) =>
	(props.visibleItems || []).indexOf(value) > -1;

const handleDetailsClick = (userId: string) => emit("onDetailsClick", userId);

const handleEditClick = (userId: string) => emit("onEditClick", userId);
</script>

<style scoped lang="postcss">
li.heading {
	position: sticky;
	top: 0;
	height: 48px;
	@apply flex leading-5 items-center bg-gray-100 text-gray-500 text-lg align-middle justify-between gap-x-4 px-3 py-3;
}

ul {
	max-height: calc(100% - 32px);
	overflow-y: auto;
}

ul.no-data li {
	text-align: center;
	font-size: 1.6rem;
	color: #999;
	border: none;
	padding: 5px;
}
</style>
