import { Composer } from "vue-i18n";
import { i18n } from "@/i18n";
import IUser from "@/interfaces/users/iUser";
import { get, put, post } from "./apiService";
import IConnection from "@/interfaces/users/iConnection";
import { IUniqueExport } from "@/interfaces/users/iUniqueExport";
import { IUserExportsResponse } from "./interfaces/IUserExportsResponse";
import { mapUserExportsResponse } from "./mappers/usersMappers";

const localization: Composer = i18n.global;
const BASE_URL = "users";

export const getUsers = async (): Promise<IUser[]> => {
	const result = await get<IUser[]>(BASE_URL);

	if (result.status !== 200) {
		const errorMessage = localization.t("errors.cannot-connect-server", {
			status: result.status,
			statusText: result.statusText,
		});
		throw new Error(errorMessage);
	}

	if (!result.data) {
		const errorMessage = localization.t("errors.cannot-get-users-data");
		throw new Error(errorMessage);
	}

	return result.data;
};

export const createUser = async (userData: IUser): Promise<void> => {
	await post<IUser>(`${BASE_URL}/new`, userData);
};

export const updateUser = async (userData: IUser): Promise<void> => {
	await put<IUser>(`${BASE_URL}/update`, userData);
};

export const updateConnections = async (
	userId: string,
	connections: IConnection[]
): Promise<void> => {
	const requestBody = {
		id: userId,
		connections,
	};

	await put<IConnection[]>(`${BASE_URL}/connections`, requestBody);
};

export const getUserExports = async (
	userId: string
): Promise<IUniqueExport[]> => {
	const result = await get<IUserExportsResponse>(
		`${BASE_URL}/${userId}/exports`
	);

	const mappedResult = mapUserExportsResponse(result.data);

	return mappedResult;
};
