<template>
	<ho-modal-dialog
		:headerText="editMode ? 'Edit user data' : 'Create a user'"
		:hide-footer="true"
		:width="480"
		@onCancel="handleModalCancel"
	>
		<template v-slot:body>
			<create-user-form 
				:loading="loading"
				:edit-mode="editMode"
				:user-data="userData"
				@on-close="handleCreateUserDialogClose"
			/>
		</template>
	</ho-modal-dialog>
</template>

<script setup lang="ts">
import HoModalDialog from "@/components/modal/ho-modal-dialog.vue";
import CreateUserForm from "./createUserForm/index.vue";
import IUser from "@/interfaces/users/iUser";

defineProps({
	userData: {
		type: Object as () => IUser,
	},
	editMode: Boolean,
	loading: Boolean
});

const emit = defineEmits(['onSubmit', 'onCancel']);

const handleCreateUserDialogClose = (data: any) => {
	data ? emit('onSubmit', data) : emit('onCancel');
};

const handleModalCancel = () => {
	emit('onCancel');
};

</script>
