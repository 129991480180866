import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-none py-3 w-16 text-center" }
const _hoisted_2 = { class: "min-w-0 flex-auto" }
const _hoisted_3 = { class: "text-2xl font-bold text-gray-900" }
const _hoisted_4 = { class: "text-gray-900" }
const _hoisted_5 = {
  key: 0,
  class: "flex-none w-[160px]"
}
const _hoisted_6 = {
  key: 1,
  class: "flex-none w-[160px]"
}
const _hoisted_7 = {
  key: 2,
  class: "flex-none w-[160px]"
}
const _hoisted_8 = {
  key: 3,
  class: "flex-none w-[100px]"
}
const _hoisted_9 = {
  key: 4,
  class: "flex-none w-[70px]"
}
const _hoisted_10 = { class: "w-7 h-7 text-center" }

import {
	CheckCircleIcon,
	XCircleIcon,
	ChevronRightIcon,
	PencilAltIcon,
} from "@heroicons/vue/outline";
import IUserListViewModel from "@/components/administration/interfaces/iUserListViewModel";


export default /*@__PURE__*/_defineComponent({
  __name: 'list-item',
  props: {
	item: Object as () => IUserListViewModel,
	selectedItemId: String,
	visibleItems: Array as () => string[],
},
  emits: ["onDetailsClick", "onEditClick"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleDetailsClick = () => emit("onDetailsClick", props.item?.id);

const handleEditItemClick = () => emit("onEditClick", props.item?.id);

const hasField = (value: string) => (props?.visibleItems || []).indexOf(value) > -1;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["flex items-center justify-between gap-x-4 px-3 py-0", __props.selectedItemId === __props.item?.id && 'bg-gray-100'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (__props.item?.active)
        ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
            key: 0,
            class: "h-10 w-10 text-green-600"
          }))
        : _createCommentVNode("", true),
      (!__props.item?.active)
        ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
            key: 1,
            class: "h-10 w-10 text-gray-500"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(__props.item?.fullName), 1),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("label", _hoisted_4, _toDisplayString(__props.item?.email), 1)
    ]),
    (hasField('city'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(__props.item?.role), 1)
        ]))
      : _createCommentVNode("", true),
    (hasField('city'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("label", null, _toDisplayString(__props.item?.city), 1)
        ]))
      : _createCommentVNode("", true),
    (hasField('declarantTypeLabel'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("label", null, _toDisplayString(__props.item?.declarantTypeLabel), 1)
        ]))
      : _createCommentVNode("", true),
    (hasField('connections'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("label", null, _toDisplayString(__props.item?.connections?.length), 1)
        ]))
      : _createCommentVNode("", true),
    (!__props.selectedItemId || __props.selectedItemId === __props.item?.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "btn btn-xs flex items-end",
            onClick: handleEditItemClick
          }, [
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_unref(PencilAltIcon), { class: "w-6 h-7" })
            ]),
            _cache[1] || (_cache[1] = _createTextVNode(" Edit "))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "flex-none py-3 w-14",
      onClick: handleDetailsClick
    }, [
      _createElementVNode("p", null, [
        _createVNode(_unref(ChevronRightIcon), { class: "h-8 w-8 text-gray-600" })
      ])
    ])
  ], 2))
}
}

})