<template>
	<div class="ho-search-bar flex">
		<div class="ho-autoselect">
			<autocomplete :options="searchOptions" :default-value="searchFilter" :can-clear="false"
				@onChange="handleChangeSearchCriteria" />
		</div>
		<div class="ho-search-input">
			<input autocomplete="none" aria-autocomplete="none" type="text" v-model="searchText"
				:placeholder="`Search by name or email...`" @input="handleChangeInput" @keyup.enter="handleSearch" />
			<button class="flex ho-search-icon" @click="handleSearch">
				<span class="w-8 h-8 text-center"><search-icon class="w-7 h-7" /></span>
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import Autocomplete from "@/components/form/autocomplete.vue";
import { SearchIcon } from "@heroicons/vue/outline";

const props = defineProps({
	selected: Object as () => { searchField: string; searchText: string },
	searchOptions: {
		type: Array as () => { label: string; value: string }[],
		default: () => [],
	},
});

const emit = defineEmits(["update:modelValue", "onSearch", "onChange"]);

const searchFilter = ref(
	props.selected?.searchField ?? props.searchOptions[0].value
);
const searchText = ref(props.selected?.searchText ?? "");

const handleChangeInput = (value: any) => {
	if (value.target.value === "") {
		handleSearch();
	}
};

const handleChangeSearchCriteria = (value: string) => {
	searchFilter.value = value;
};

const handleSearch = () => {
	emit("onSearch", {
		searchField: searchFilter.value,
		searchText: searchText.value,
	});
};
</script>

<style lang="postcss" scoped>
.ho-search-bar {
	position: relative;
}

.ho-search-bar .ho-search-input input {
	background-color: white;
	min-width: 250px;
	padding-right: 32px;
	border: none;
}

.ho-search-bar .ho-search-input .ho-search-icon {
	position: absolute;
	right: 5px;
	top: 8px;
}

.ho-search-bar .ho-autocomplete {
	background: rgba(255, 255, 255, 0.8);
	display: inline-block;
	min-width: 100px;
	margin-right: 8px;
	margin-bottom: 8px;
	cursor: pointer;

	@apply rounded;
}
</style>
