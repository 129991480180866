<template>
	<div class="header flex w-full items-center">
		<h3 class="flex-auto">Users</h3>
		<button class="btn flex" @click="goToHome">
			<span class="w-8 h-8 text-center"><home-icon class="w-7 h-7" /></span>
			Home
		</button>
	</div>
	<div class="filters flex w-full items-center justify-between">
		<div class="flex items-center pb-0 ho-search-bar">
			<users-search-bar :selected="selectedFilters" :search-options="selectOptionsList" @onSearch="handleSearchUsers" />
		</div>
		<button class="btn flex-none" @click="handleOpenCreateUser">
			Create user
		</button>
	</div>
	<div class="users flex w-full">
		<div class="user-list flex-1" :class="selectedUserId && 'condensed'">
			<list :items="usersViewModel" :visible-items="selectedUserId
				? [...visibleFields]
				: [...visibleFields, ...hiddenFields]
				" :selectedUserId="selectedUserId" :key="selectedUserId" @on-details-click="handleDetailsOpen"
				@on-edit-click="handleOpenEditUser" />
		</div>
		<div class="user-details flex-1" v-if="selectedUserData?.id">
			<div class="flex rounded-t-lg border bg-gray-100 text-gray-500 align-middle justify-between px-3 py-3">
				<label class="flex-1">User Details</label>
				<XIcon class="h-8 w-8" @click="handleDetailsClose" />
			</div>
			<router-view :user="selectedUserData" @onReloadTable="fetchUsers"></router-view>
		</div>
	</div>

	<create-user-dialog v-if="createUserOpen" :loading="loading.user" :user-data="editUserData" :editMode="editMode"
		@onSubmit="handleCreateEditUser" @onCancel="handleCloseCreateUser" />
</template>

<script setup lang="ts">
import { Ref, onBeforeMount, ref, watch } from "vue";
import { LocationQuery, RouterView, useRoute } from "vue-router";
import router from "@/router";
import { createUser, getUsers, updateUser } from "@/services/usersApi";
import List from "@/components/administration/users/list/list.vue";
import { XIcon, HomeIcon } from "@heroicons/vue/outline";
import IUser from "@/interfaces/users/iUser";
import CreateUserDialog from "@/components/administration/users/create-user-dialog.vue";
import UsersSearchBar from "@/components/administration/users/usersSearchBar/index.vue";
import { mapUsersData } from "./utils";
import { hiddenFields, visibleFields } from "./config";

interface IUsersQueryFilters {
	searchField?: string;
	searchText?: string;
}

const selectOptionsList = [
	{
		label: "Name",
		value: "fullName",
	},
	{
		label: "Email",
		value: "email",
	},
];

const route = useRoute();

const getSelectedUserData = (userId?: string) => {
	if (!userId) {
		return undefined;
	}

	return usersData.value?.find((u) => u.id.toString() === userId);
};

const params = route.params;
const queryParams = route.query;
const id: string | undefined = params.id as string;

const usersData: Ref<IUser[]> = ref([]);
const usersViewModel: Ref<any[]> = ref([]);
const detailsOpen = ref(false);
const selectedUserId: Ref<string | undefined> = ref(id || undefined);
const selectedUserData: Ref<IUser | undefined> = ref(getSelectedUserData(id));
const editUserData: Ref<IUser | undefined> = ref(undefined);
const createUserOpen = ref(false);
const loading = ref({ user: false });
const editMode = ref(false);

const initQueryParams = {
	searchField: queryParams.seachField?.toString() ?? selectOptionsList[0].value.toString(),
	searchText: queryParams.searchText?.toString() ?? "",
};
const selectedFilters = ref(initQueryParams);
const lastQueryParams = ref(initQueryParams);

const setUsersList = (allUsers: IUser[], queryFilters?: IUsersQueryFilters, condenseList = false) => {
	if (detailsOpen.value === true) {
		return usersViewModel.value;
	}

	const config = {
		visibleFields: condenseList
			? [...visibleFields]
			: [...visibleFields, ...hiddenFields],
	};
	const mappedUsers = mapUsersData(allUsers, config);

	if (queryFilters?.searchField && queryFilters?.searchField.length > 0) {
		const foundUsers = mappedUsers.filter((user) => {
			if (
				queryFilters?.searchField &&
				user[queryFilters.searchField] &&
				user[queryFilters?.searchField]
					.toLowerCase()
					.indexOf(queryFilters?.searchText?.toLowerCase()) >= 0
			) {
				return user;
			}
		});

		usersViewModel.value = foundUsers;
	} else {
		usersViewModel.value = [...mappedUsers];
	}
};

const fetchUsers = async () => {
	usersData.value = await getUsers();
	const queryFilters = parseQueryFilters(queryParams);

	setUsersList(usersData.value, queryFilters);

	if (selectedUserId.value) {
		const selectedUser = getSelectedUserData(selectedUserId.value);
		selectedUserData.value = selectedUser;
	}
};

onBeforeMount(fetchUsers);

// TODO: General composable to handle query fiters
const parseQueryFilters = (
	query?: LocationQuery
): IUsersQueryFilters | undefined => {
	if (!query) {
		return undefined;
	}

	const filters: { [key: string]: string } = {};
	for (const key in query) {
		(filters as any)[key] = query[key];
	}

	return filters as unknown as IUsersQueryFilters;
};

watch(
	() => route.query,
	(newParams) => {
		if (route.name === 'Users') {
			const filters = parseQueryFilters(newParams);
			setUsersList(usersData.value, filters);

			if (detailsOpen.value === true) {
				detailsOpen.value = false;
			}
		}
	}
);

const handleDetailsOpen = (userId: string) => {
	const selectedUser = getSelectedUserData(userId);
	selectedUserData.value = selectedUser;
	selectedUserId.value = userId;

	setUsersList(usersData.value, lastQueryParams.value, true);
	setDetailsOpenView(true);

	router.push({ name: "User", params: { id: userId } });
};

const handleDetailsClose = () => {
	setDetailsOpenView(false);
	selectedUserId.value = undefined;
	selectedUserData.value = undefined;

	if (lastQueryParams.value.searchText?.length > 0) {
		router.push({
			path: "/admin/users",
			query: lastQueryParams.value,
		});
	} else {
		router.push("/admin/users");
	}
};

const setDetailsOpenView = (open: boolean) => {
	detailsOpen.value = open;
};

const handleOpenCreateUser = () => {
	editUserData.value = undefined;
	editMode.value = false;
	createUserOpen.value = true;
};

const handleOpenEditUser = (userId: string) => {
	editUserData.value = getSelectedUserData(userId);
	editMode.value = true;
	createUserOpen.value = true;
};

const handleCloseCreateUser = () => {
	editUserData.value = undefined;
	editMode.value = false;
	createUserOpen.value = false;
};

const handleCreateEditUser = async (data: any) => {
	editMode.value
		? editUserData.value?.id &&
		(await updateUser({ ...data, id: editUserData.value?.id }))
		: await createUser(data);

	handleCloseCreateUser();
	fetchUsers();
};

const goToHome = () => router.push("/vat-explorer");

const handleSearchUsers = (search: {
	searchField: string;
	searchText: string;
}) => {
	if (detailsOpen.value === true) {
		handleDetailsClose();
	}

	if (search.searchText?.length > 0) {
		const newQueryParams = { ...search };

		lastQueryParams.value = newQueryParams;

		router.push({
			path: "/admin/users",
			query: newQueryParams,
		});
	} else router.push("/admin/users");
};
</script>

<style lang="postcss" scoped>
.wrapper {
	margin: 0 auto;
}

.header {
	height: 60px;
}

.filters {
	height: 60px;
}

.users {
	height: calc(100% - 120px)
}

.user-list.condensed {
	@apply w-36;
}
</style>
